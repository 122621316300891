.main-container {
  width: 100%;
}

.graph-container {
  width: 100%;
  display: flex;
  background-color: rgba(28, 31, 44, 1);
  padding-bottom: 50px;
}

.navbar {
  width: 100%;
  height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(28, 31, 44, 1);
}
.newMyClass {
  position: absolute;
  width: 300px;
  left: 50px;
}

.bar-chart {
  width: 30%;
  /* background-color: rgb(240, 240, 151); */
  display: flex;
  justify-content: center;
  padding-left: 47px;
  flex-direction: column;
  padding-top: 20px;
}

.call-bar {
  width: 100%;
  /* background-color: violet; */
  background-color: rgba(32, 37, 52, 0.934);
  height: 410px;
  border: 2px solid rgb(101, 91, 91);
  border-radius: 14px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.card {
  width: 100%;
  position: relative;
}

.line-chart {
  width: 70%;
  /* background-color: rgba(32, 37, 52, 0.934); */
  display: flex;
  justify-content: center;
  /* background-color: yellow; */
}

.heading_bar_chart {
  display: flex;
  flex-direction: column;
  padding-top: 13px;
}

h1 {
  margin: 5px 0px 5px 10px;
  position: relative;
  letter-spacing: 1px;
  font-weight: 400;
  /* border-bottom: 1px solid rgb(246, 239, 239); */
}
.slider-handle {
  position: absolute;
  right: 10px;
  top: 30px;
  padding-right: 30px;
}

/* Slider Css  */
.my-slider {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  /* top: -90px; */
}
.my-slider h3 {
  position: relative;
  padding: 8px 0px;
  top: 3px;
  left: -25px;
}

.multi-range-slider-container {
  width: 1100px !important;
}

.divOutput {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 10px;
}
.divOutput > div {
  width: 50%;
}
.divOutput > div:first-child {
  text-align: right;
  padding-right: 10px;
}
.divOutput > div:nth-child(2) {
  text-align: left;
  padding-left: 10px;
}
.divOutput span {
  margin: 10px;
}

.label {
  font-size: 16px !important;
  font-weight: 500;
}

.p-connected-overlay-enter-done {
  border: 1px solid rgb(101, 91, 91);
}

.p-datatable-striped {
  width: 95%;
  height: 100%;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr {
  background-color: rgba(28, 31, 44, 1);
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: rgba(28, 31, 44, 1);
}

.p-skeleton-circle {
  margin: 20px;
  visibility: hidden !important;
}

.p-skeleton {
  margin: 15px;
}
.skalatonHolder {
  margin-left: 10%;
  width: 90%;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.skalatonHolder .card {
  width: 80%;
}
.p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
}
.p-datatable .p-datatable-thead > tr > th {
  border: none !important;
}

/* MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium MuiAutocomplete-clearIndicator  */

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  color: white !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #1976d2 !important;
}

/* Hide the cut button in autocomplete field */
/* MuiIconButton-root MuiIconButton-sizeMedium MuiAutocomplete-clearIndicator css-zvo6a0 */
.css-1ow00yu-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  display: none !important;
}

.MuiAutocomplete-endAdornment > .MuiAutocomplete-clearIndicator {
  display: none !important;
}
