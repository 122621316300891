.header_main {
  margin: 0%;
  background-color: rgba(28, 31,44, 1);
  height: 150px;
  padding: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px 0px inset, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px;
  border-bottom: 3px solid white ;
}

.heading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5px;
  /* border-bottom: 1px solid white; */
  position: relative;
}

.timer {
  margin: 0%;
  display: flex;
  justify-content: right;
}

.timer_left {
  margin: 0%;
  padding: 10px 20px 0px 0px;
  color: rgb(248, 244, 248);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: 500; */
  gap: 1px;
}
.headPoint {
  position: relative;
  top: -10px;
}
#mr {
  font-size: 22px;
  font-weight: 500;
  padding-top: 10px;
}

h3 {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 40px;
  font-weight: 500;
  color: rgb(248, 244, 248);
  word-spacing: 3px;
  margin: 0%;
  padding: 0%;
  letter-spacing: 0.5px;
  position: relative;
  left: 8px;
}

h4 {
  margin: 0%;
  padding: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: aliceblue;
  font-weight: 500;
  margin-top: 5px;
  word-spacing: 1px;
}

.secondNumber {
  font-size: 20px;
  letter-spacing: 0.5px;
}

.thirdNumber {
  font-size: 18px;
  letter-spacing: 0.5px;
}

.imageholder {
  position: absolute;
  left: 45px;
  top: 15px;
}

.imageholder img {
  width: 120px;
  height: 110px;
  /* background-color: #1db955; */
  padding: 5px;
  filter: drop-shadow(3px 5px 2px rgba(255, 255, 255, 0.785));
}

.imageholdersec {
  position: absolute;
  left: 200px;
  top: 10px;
}

.imageholdersec img {
  width: 150px;
  height: 150px;
  /* background-color: #1db955; */
  padding: 5px;
  filter: drop-shadow(3px 5px 2px rgba(255, 255, 255, 0.785));
}
