.p-multiselect-panel .p-multiselect-header{
    background-color: rgba(28, 31,44, 1);
}

.css-1vovazs{
  color: white !important;
  font-size: 20px !important;
}

/* Track */
::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar handle */
    border-radius: 5px; /* Rounded corners of the scrollbar handle */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar handle on hover */
  }
  
  /* Track when scrollbar is in the bottom */
  ::-webkit-scrollbar-corner {
    background: #eee; /* Background color of the scrollbar corner */
  }
  
  body{
    background-color: rgba(28, 31,44, 1);
  }