.handlemulti{
    margin-left: 10px;
}
.handlemultiselector{
    position: relative;
    top: 0px;
    left: 2px;
    /* background-color: aqua; */
    z-index: 100;
    width: 100%;
    height: 80px;
    display: flex;
    gap: 5px;
    padding: 5px;
    justify-content: space-between;
    
}
.radioHolder{
    width: 20%;
    height: 100%;
    /* background-color: bisque; */
    display: flex;
}
.pplsector{
    height: 100%;
    /* background-color: yellowgreen; */
    position: absolute;
    display: flex;
    right: 0px;
}
.pplsector h4{
    font-size: 30px;
    position: relative;
    left: -25px;
    top: 20px;
}

.css-u4tvz2-MuiFormLabel-root{
    color: white !important;
    font-size: 20px !important;
}
.pplwidthhandle{
    width: 350px;
    background-color: rgba(28, 31,44, 1);
   

}

.pplsector .multi-range-slider-container{
    width: 100%;
}


.pplsector .multi-range-slider .bar-inner {
    background-color: blue;
} 
.line_graph{
    width: 95%;
    display: flex;
    flex-direction: column;
    position: relative;
    /* justify-content: center;
    align-items: center; */
}

.line_graph_sec{
    width: 100%;
    position: absolute;
    top: 88px;
    margin-top: 30px;
    padding: 20px 15px;
    border: 2px solid rgba(206, 166, 166, 0.34);
    border-radius: 10px;
}

.p-multiselect-items-wrapper{
    background-color: rgba(28, 31,44, 1);
}

.pplsector .multi-range-slider .thumb-right{
    background-color: rgb(247, 170, 82) !important;
}
/* .thumb .thumb-right{
    background-color: yellow !important;
} */

.multi-range-slider {
  
   border: none !important;
}